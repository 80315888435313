
/*# sourceMappingURL=style.css.map */
/* JR Custom CSS */
* {
  margin: 0;
  padding: 0;
}

.container {
  max-width: 960px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  padding: 0px 40px;
  position: relative;
  clear: both;
}

.navigation-body {
  padding: 25px;
  background-color: white;
}


/* page animation */
.about__container,
.resume__container,
.blog__container,
.contact__container {
  -webkit-animation: 800ms ease-in-out 0s normal none 1 running customOne;
  animation: 800ms ease-in-out 0s normal none 1 running customOne;
  padding-left: 20px;
  padding-right: 20px;
}




@keyframes customOne {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

.customOne {
  animation-name: customOne;
}

/* Right part in the site */
.main__section {
  position: fixed;
  top: 0px;
  left: 30%;
  bottom: 0px;
  right: 0px;
  overflow-x: hidden;
  overflow-y: auto;
  float: right;
  width: 75%;
}
.landing__page {
  background-color:#14213d;
  height: 100vh;
}
.landing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15rem;
}

.social_container {
  padding-top: 25px;
  padding-bottom: 25px;
}

.sunrise-font {
  font-family: 'Waiting for the Sunrise', cursive; 
  font-size:30px; 
  margin: 10px 50px;
  text-align: center; 
  letter-spacing: 6px; 
  font-weight: bold;
  color: #edf2f4;

}

.btn {
  width: 12rem;
  height: 4rem;
  font-family: 'Roboto', sans-serif;
  font-size: .9rem;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 500;
  color: #edf2f4;
  background-color: #fca311;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}
.btn:hover {
  cursor: pointer;
  background-color: #fca311;
  box-shadow: 0px 15px 20px rgba(255, 213, 77, 0.445);
  color: #edf2f4;
  transform: translateY(-7px);
}

.btn:active {
  box-shadow: 0px 10px 15px rgba(255, 213, 77, 0.445);

}

.btn-discover {
  margin-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

 .leftpart {
  width: 20%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  z-index: 10;
  padding: 0px 100px;
  background-color: #fff;
  float: left;
}
.leftpart_inner {
  width: 100%;
  height: auto;
}

.leftpart img {
  max-width: 150px;
}


.leftpart .menu {
  padding: 40px 0px 50px 0px;
  width: 100%;
  float: left;
}
.leftpart .menu ul {
  margin: 0px;
  list-style-type: none;
}
.leftpart .menu ul li {
  margin: 0px;
  width: 100%;
  text-decoration: none;
  color: #767676;
  display: inline-block;
  font-weight: 500;
  font-family: "Montserrat";
  transition: all 0.3s ease;
  cursor: pointer;
  padding-bottom: 10px;
  letter-spacing: 2px;
  font-size: 1.25rem;

}
.leftpart .menu > ul li:hover {
  color: #000;
  letter-spacing: 2px;
}
.leftpart .menu ul li.react-tabs__tab--selected {
  color: #000;
  letter-spacing: 2px;
}

.leftpart .copyright p {
  color: #999;
  font-family: "Montserrat";
  line-height: 25px;
  font-style: italic;

}

.menu__content {
  padding-bottom: 10px;
}


.rightpart {
  width: 100%;
  min-height: 100vh;
  float: left;
  position: relative;
  background-color: #f8f8f8;
  padding-left: 450px;
}
.rightpart_in {
  position: relative;
  width: 100%;
  float: left;
  clear: both;
  border-left: 1px solid #ebebeb;
  min-height: 100vh;
}


.svg {
  width: 24px;
  height: 24px;
}

a, p {
  text-decoration: none;
  color: #787878;
  font-weight: 500;
  padding-left: 5px;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.social {
  width: 100%;
  float: left;
}
.social {
  margin: 0px;
  list-style-type: none;
}
.social li {
  margin: 0px 15px 0px 0px;
  display: inline-block;
}
.social li:last-child {
  margin-right: 0px;
}
.social li a {
  text-decoration: none;
  color: #000;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.social li a:hover {
  filter: opacity(0.7);
}
.social li .svg {
  width: 24px;
  height: 24px;
}



/* ABOUT */

img {
  width: 65%;
  height: 100%;

}
.resume {
  width: 100%;
    height: 100%; 
}


.about__container {
  padding-top: 5%;
}

.about__image {
  padding-bottom: 3rem;
}
.abt_tm_about {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  padding-top: 2rem;
}

.headline {
  font-size: 22px;
  letter-spacing: 2px;
  padding-left: 3px;
  padding-bottom: 1rem;
}



.abt_tm_button {
  margin-top: 2rem;
}

.description {
  padding-top: 2rem;
}

.desc {
  letter-spacing: .5px;
  line-height: 24px;
}

.right {
  width: 50%;
  padding-right: 50px;
}

.abt_tm_modalbox_about .description_wrap {
  position: relative;
  padding: 0;
  overflow: auto;
}
.abt_tm_modalbox_about .my_box {
  width: 100%;
  height: auto;
  clear: both;
  display: flex;
  margin-bottom: 92px;
}
.abt_tm_modalbox_about .my_box .left {
  width: 50%;
  padding-right: 50px;
}
.abt_tm_modalbox_about .about_title h3 {
  font-weight: 700;
  color: #000;
  font-size: 20px;
}
.abt_tm_modalbox_about {
  width: 45rem;
  display: flex;
  flex-direction: column;
  padding: 2rem;
}
.close-modal {
  align-self: flex-end;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 20px;
  padding-bottom: 20px;
   
}
.abt_progress {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  padding-top: 33px;
}
.progress_inner {
  width: 100%;
  margin-bottom: 17px;
}
.progress_inner:last-child {
  margin-bottom: 0px;
}
.progress_inner > span {
  margin: 0px 0px 5px 0px;
  width: 100%;
  display: block;
  text-align: left;
  color: #000;
  font-style: italic;
}
.progress_inner span.number {
  float: right;
}
.progress_inner .background {
  background: rgba(0, 0, 0, 0.09);
  width: 100%;
  min-width: 100%;
  position: relative;
  height: 3px;
}
.progress_inner .background .bar_in {
  height: 100%;
  background: #000;
  width: 0px;
  overflow: hidden;
}
.progress_inner .background .bar {
  width: 100%;
  height: 100%;
}
.progress_inner .background .bar.open {
  -webkit-animation: wow 2s cubic-bezier(0.165, 0.84, 0.44, 1); /* Safari 4+ */
  -moz-animation: wow 2s cubic-bezier(0.165, 0.84, 0.44, 1); /* Fx 5+ */
  animation: wow 2s cubic-bezier(0.165, 0.84, 0.44, 1); /* IE 10+ */
  width: 100%;
}

@-webkit-keyframes wow {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
@-moz-keyframes wow {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
@keyframes wow {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

body.modal {
  overflow-y: hidden;
}


.ReactModal__Content .ReactModal__Content--after-open {
  transform: translate(-25%, -50%) !important;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}



.resume__container {
  padding-top: 2rem;
}

.download-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 1rem;
}

.download-button {
  cursor: pointer;
}

.resume__page--header {
  padding-left: 1px;
  font-weight: 500;
  font-family: "Montserrat";
  transition: all 0.3s ease;
  letter-spacing: 2px;
  font-size: 1.25rem;
}


/* CONTACT */
.contact__container {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  padding: 100px 0px 100px 0px;
}
.map_wrap {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  margin-bottom: 50px;

}
.contact__container .map_wrap .map > div {
  height: 400px !important;
  width: 100% !important;
}
.contact__container .map_wrap .map iframe {
  width: 100%;
  height: 100%;
}

.contact_form {
  border-radius: 5px;
}

input {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

textarea {
  width: 100%;
  min-height: 70px;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.cards {
  max-width: 960px;
  margin: 0 auto 30px;
}

article {
  position: relative;
}

.article-img {
  height: 200px;
  width: 100%;
  object-fit: cover;
}

.article-title {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.2);
}


.contact_btn {
  margin-top: 25px;
}



/* BLOG */
.cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 35px;
}

.cards {
  max-width: 960px;
  margin: 0 auto 10px;
  padding-top: 1rem;
  
}

.card {
  box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.5);
  transition: 0.3s;
}

.card:hover {
  box-shadow: 0 8px 24px 0 rgba(0,0,0,0.5);
  cursor: pointer;
}


article {
  position: relative;
}

.article-img {
  height: 400px;
  width: 100%;
  object-fit: cover;
}

.article-title {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.2);
}


.blog__container {
  padding-top: 2rem;
}
